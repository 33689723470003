import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrl: './compare-modal.component.css'
})
export class CompareModalComponent implements OnInit{

  @Input() compare_prices;
  @Output() closed = new EventEmitter();

  constructor(){

  }

  ngOnInit(){

  }

  closeModal() {
    console.log('should close tag modal')
    this.closed.emit();
  }
}
