import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-tag-modal',
  templateUrl: './tag-modal.component.html',
  styleUrls: ['./tag-modal.component.css']
})
export class TagModalComponent implements OnInit {
  @Output() closed = new EventEmitter();
  @Input() store_id;
  @Input() item_id;
  @Input() product_tags;
  tags = [];

  tagArray = new UntypedFormArray([]);

  constructor(private api: UserService) {
    this.api.getTags().subscribe(res => {
      this.tags = res;
    })
  }

  ngOnInit() {
    this.initializeArray();
  }

  initializeArray() {
    if (!this.product_tags) { // if there are no tags, instert an empty row
      this.addRow();
    } else { // if there are tags, insert them
      for (let i = 0; this.product_tags.length > i; i++) {
        const group = new UntypedFormGroup({
          item_id: new UntypedFormControl(this.product_tags[i].item_id,[
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]),
          store_id: new UntypedFormControl(this.product_tags[i].store_id, [
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]),
          tag_id: new UntypedFormControl(this.product_tags[i].tag_id, [
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]),
          from_date: new UntypedFormControl(this.product_tags[i].from_date),
          till_date: new UntypedFormControl(this.product_tags[i].till_date),
          info: new UntypedFormControl(this.product_tags[i].info, [
            Validators.pattern(/^[0-9a-zA-ZáÁéÉéíÍóÓöÖőŐúÚüÜűŰľĽĹšŠčČťŤžŽýÝäňŇôŔŘďĎ\s]*$/)
          ])
        });
        this.tagArray.insert(i, group)
      }
    }
  }

  checkIfAlreadyExists() {
    this.tags.forEach(v => {
      v.disabled = false;
      for(let i = 0; this.tagArray.value.length > i; i++) {
        if(this.tagArray.value[i].tag_id == v.tag_id){
          v.disabled = true;
        }
      }
    })
  }

  addRow() {
    this.checkIfAlreadyExists();
    const group = new UntypedFormGroup({
      item_id: new UntypedFormControl(this.item_id, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      store_id: new UntypedFormControl(this.store_id, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      tag_id: new UntypedFormControl(0, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      from_date: new UntypedFormControl(null),
      till_date: new UntypedFormControl(null),
      info: new UntypedFormControl('',[
        Validators.pattern(/^[0-9a-zA-ZáÁéÉéíÍóÓöÖőŐúÚüÜűŰľĽĹšŠčČťŤžŽýÝäňŇôŔŘďĎ\s]*$/)
      ])
    });
    const index = this.tagArray.length + 1
    this.tagArray.insert(index, group);

  }

  removeRow(i) {
    this.tagArray.removeAt(i);
    this.checkIfAlreadyExists();
  }

  closeTagModal() {
    console.log('should close tag modal')
    this.closed.emit();
  }

  onTagSubmit() {
    //console.log(this.tagArray)
    if(this.tagArray.length === 0 ){
      this.api.removeTagsForItem(this.item_id, this.store_id).subscribe(res => {
        this.closeTagModal();
      })
    } else {
      this.api.addTagForItem(this.tagArray.value).subscribe(res => {
        //console.log(res)
        this.closeTagModal();
      })
    }
  }


}
