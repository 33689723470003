import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductfilterPipe } from '../pipes/productfilter.pipe';
import { UserService } from '../services/user.service';
import { TagModalComponent } from '../tag-modal/tag-modal.component';
import { StoreModalComponent } from '../store-modal/store-modal.component';
import { CompareModalComponent } from '../compare-modal/compare-modal.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  @ViewChild(TagModalComponent,{static:false}) TagModal;
  @ViewChild(StoreModalComponent,{static:false}) StoreModal;
  @ViewChild(CompareModalComponent,{static:false}) CompareModal;

  products = [];
  product = [];
  filtered = [];
  search = '';
  stores = [];
  sel_stores = [];
  store_id: number = 0;
  item_id: number = 0;
  product_tags = [];
  alts = [];
  alt_items = [];

  ancestors = [];
  descendants = [];

  store_products = [];
  showTagModal:boolean = false;
  showStore:boolean = false;
  showAltModal:boolean = false;
  showCompareModal:boolean = false;

  category_id:number = 1;
  stock_statuses = [];
  timestamp;
  product_count:number = 0;
  compare_prices = [];

  constructor(private api: UserService, private router: Router, private pipe: ProductfilterPipe, private route: ActivatedRoute) {
    this.route.params.subscribe( params => {
      if(params.category_id) {
        this.category_id = params.category_id;
        this.getProducts()
      } else {
        this.getProducts()
      }
    })
    this.timestamp = (new Date()).getTime();
  }

  ngOnInit() {
      //this.getProducts();
  }

  ngAfterViewInit(){
    this.route.params.subscribe( params => {
      if(params['item_id'] && params['item_id'] > 0) {
        setTimeout(() => {
          document.getElementById(params['item_id']).scrollIntoView({behavior:'smooth'});
        },2000)
      }
    })
  }

  scrollToTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  async getProducts() {
    this.products = await this.api.getProducts(this.category_id,this.store_id).toPromise()
    this.stores = await this.api.getStores().toPromise();
    this.stock_statuses = await this.api.getStockStatuses().toPromise();
    let parents = await  this.api.getCategoryOptions(this.category_id).toPromise();
    this.ancestors = await parents.ancestors;
    this.descendants = await parents.descendants;
    this.descendants.sort((a,b) => a.title.localeCompare(b.title));
    this.alts = await this.api.getAlts().toPromise();
    this.product_count = await this.api.getProductCount(this.store_id).toPromise();
    this.loadList();
  }

  loadList() {
    this.filtered = this.products.slice(0, 50);
    // setTimeout(() => {
    //   this.filtered = this.products;
    // },5000)
  }

  filter() {
    if (this.search.length > 0) {
      this.filtered = this.pipe.transform(this.products, [this.search])
    } else {
      this.loadList();
    }
  }

  resetFilter() {
    if (this.search.length == 0) {
      this.getProducts();
    }
  }

  loadAll() {
    console.log('should load all items')
    this.filtered = this.products;
  }

  edit(product) {
    this.router.navigate(['product/edit', { data: product }]);
  }

  convertToInt(price, VAT) {
    let selling_price = parseFloat(price);
    let DPH = parseFloat(VAT);
    let val = Math.round((selling_price/100*DPH + selling_price)*100 ) /100;
    return val;
  }

  removeProduct(id) {
    if(confirm("Naozaj chcete vymazať produkt z obchodu?")) {
      this.api.removeProductFromStore(this.store_id, id).subscribe(res => {
        this.getProducts();
      })
    }
  }

  compareModal(id) {
    this.compare_prices = [];
    this.api.compareProductPrices(id).subscribe(res => {
      this.compare_prices = res;
      this.showCompareModal = true;
    })
  }

  compareModalClose(event){
    this.showCompareModal = false;
    this.compare_prices = [];
  }

  showStoreModal(product) {
    this.product = product;
    this.api.getStoresForProduct(product.item_id).subscribe(res => { // we need to check which stores have the product already .. those stores shouldn't be selectable, to avoid duplicity
      this.sel_stores = res;
      this.showStore = true;
    })
  }

  storeModalClose(event){
    console.log('should close store modal from parent');
    this.showStore = false;
    this.getProducts();
  }


  editStoreModal(product) {
    this.product = product;
    this.showStoreModal(product);
  }

  tagModal(id) {
    this.item_id = id;
    let product = this.products.find(x => x.item_id == id);
    this.product_tags = product.tags;
    this.showTagModal = true;
  }

  tagModalClose(event){
    console.log('should close tag modal from parent');
    this.showTagModal = false;
    this.getProducts();
  }

  productAltChanged(product){
    console.log("product changed")
    console.log(product)
    this.api.addAltForItem(product).subscribe(res => {
      this.getProducts();
    })
  }

  showAltsForItem(alt_id)
  {
    this.api.getAltItems(alt_id).subscribe(res => {
      this.alt_items = res;
      console.log(this.alt_items);
      this.showAltModal = true;
    })
  }

  altModalClose(event)
  {
    this.showAltModal = false;
    this.alt_items = [];
  }

}
