import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private api: ApiService) { }

  login() {
    return this.api.post(`api/login`, {});
  }

  getUserInfo() {
    return this.api.post(`api/user-profile`, {});
  }

  getProducts(category_id,store_id) {
    return this.api.get(`items/category/${category_id}/store/${store_id}`);
  }

  getProductCount(store_id) {
    return this.api.get(`item/count/${store_id}`);
  }

  getProductDetail(id) {
    return this.api.get(`item/${id}`)
  }

  updateProduct(id, data) {
    return this.api.put(`item/${id}`, data);
  }

  uploadProductImg(id, file, type) {
    //console.log(type);
    let formData = new FormData();
        formData.append('upfile', file);
        formData.append('id', id);

      return this.api.postData(`item/${type}`, formData);

  }

  removeThumb(id,file) {
    return this.api.get(`item/thumb/${id}/${file}`);
  }

  removeProduct(id) {
    return this.api.delete(`item/remove/${id}`);
  }

  createProduct(data) {
    return this.api.post(`item`, data);
  }

  // getProductForStore(store_id) {
  //   return this.api.get(`item/store/${store_id}`);
  // }

  addProductForStore(data) {
    return this.api.post(`item/store/add`,data);
  }

  removeProductFromStore(store_id, item_id) {
    return this.api.delete(`item/store/remove/${store_id}/${item_id}`);
  }

  updateProductForStore(data, store_id, item_id) {
    return this.api.post(`item/store/update/${store_id}/${item_id}`, data);
  }

  compareProductPrices(item_id) {
    return this.api.get(`compare/${item_id}`);
  }

  getCategories(){
    return this.api.get(`categories`);
  }

  getCategoryOptions(category_id){
    return this.api.get(`categoryoptions/${category_id}`);
  }

  // getCategoryPaths(){
  //   return this.api.get(`categorypaths`);
  // }

  getCategory(type,id) {
    return this.api.get(`category/${type}/${id}`);
  }

  addCategory(data){
    return this.api.post(`category`,data);
  }

  updateCategory(id,data) {
    return this.api.put(`category/${id}`, data);
  }

  getStores(){
    return this.api.get(`stores`);
  }

  getStore(id) {
    return this.api.get(`store/${id}`);
  }

  getStoresForProduct(id) {
    return this.api.get(`stores/item/${id}`);
  }

  addStore(data){
    return this.api.post(`store`,data);
  }

  updateStore(id, data){
    return this.api.put(`store/${id}`, data);
  }

  getAlts() {
    return this.api.get(`alts`);
  }

  getAlt(id) {
    return this.api.get(`alt/${id}`);
  }

  addAlt(data) {
    return this.api.post(`alt`, data);
  }

  updateAlt(id,data)  {
    return this.api.put(`alt/${id}`,data);
  }

  getTags(){
    return this.api.get(`tags`);
  }

  getTag(id) {
    return this.api.get(`tag/${id}`);
  }

  getTagsForProduct(id) {
    return this.api.get(`tags/item/${id}`);
  }

  getStockStatuses() {
    return this.api.get(`stockstatuses`);
  }

  addTag(data){
    return this.api.post(`tag`,data);
  }

  updateTag(id, data){
    return this.api.put(`tag/${id}`, data);
  }

  addTagForItem(data) {
    return this.api.post(`item/tag/add`,data);
  }

  removeTagsForItem(item_id, store_id) {
    return this.api.delete(`item/tag/remove/${item_id}/${store_id}`);
  }

  addAltForItem(data) {
    return this.api.post(`item/alt/add`, data);
  }

  removeAltForItem(item_id,alt_id) {
    return this.api.delete(`item/alt/remove/${item_id}/${alt_id}`);
  }

  getAltItems(alt_id) {
    return this.api.get(`item/alts/${alt_id}`);
  }

  //users
  getUsers() {
    return this.api.get(`users`);
  }

  getStoresForUser(uid) {
    return this.api.get(`userstores/${uid}`);
  }

  updateStoresForUser(data) {
    return this.api.post(`userstores`, data);
  }

}
